import React, { useState, useEffect, useContext } from 'react';
import ReactHTMLParser from 'react-html-parser';
import { marked } from 'marked';
import { Box } from '@latitude/box';
import { AccordionSidebar } from '@latitude/accordion';
import { Heading4, Heading5 } from '@latitude/heading';
import { ImportantInformation } from '@latitude/important-information';
import { List, ListItem } from '@latitude/list';
import { HorizontalRule } from '@latitude/horizontal-rule';
import { Metadata } from '@latitude/metadata';
import { FeaturesSlider } from '@latitude/features-slider';
import {
  ALIGN,
  BREAKPOINT,
  COLOR,
  HEADING,
  MARGIN
} from '@latitude/core/utils/constants';
import Layout from '@/components/layout';
import { SITE_URL } from '@/utils/constants';
import Hero from './_hero';
import faqs from './_faqs';
import StickyNavigation from './_sticky-navigation';
import imgHowToUse from './images/nz_digital_wallet-garmin-pay-watch.png';
import { PageContext } from '../../context/PageContext';
import { CONTENTFUL_COMPONENTS } from '../../utils/constants';
import { useSetState } from '@/utils/hooks';

const GarminPay = ({ location }) => { 
  const featureSliderItems = [
    {
      icon: 'icon-easy',
      title: 'Easy',
      text:
        'Garmin Pay lets you make purchases quickly and almost effortlessly with nothing needed but your watch. No wallet? No phone? No problem.'
    },
    {
      icon: 'icon-padlock-confetti',
      title: 'Secure',
      text:
        'Garmin Pay protects you by using watch-specific card numbers and transaction codes every time you make a purchase.'
    },
    {
      icon: 'icon-tick-circle-confetti',
      title: 'Convenient',
      text:
        'Where can you use Garmin Pay? Almost anywhere you can make contactless payments.'
    }
  ]

  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);

  const [state, setState] = useSetState({
    featureSliderData: [{
      heading: "Why Garmin Pay?",
      featureCards: featureSliderItems
    }]
  });

  useEffect(() => {
    let [featureSliders] = [[]];
    contentfulPageData?.body?.map(item => {
      switch (item.contentType.sys.id) {
        case CONTENTFUL_COMPONENTS.FEATURE_SLIDER:
          const updatedItem = {
            heading: marked(item.heading || ""),
            description: ReactHTMLParser(marked(item.description || "")),
            featureCards: item.featureCards?.map(card => ({
              ...card,
              text: marked(card.text || ""),
            }))
          };
          featureSliders = [...featureSliders, updatedItem];
          break;
        default:
          break;
      }
    });

    setState({ 
      featureSliderData: featureSliders.length > 0 ? featureSliders : state.featureSliderData,
    });
  }, [contentfulPageData]);
  /** - END - */
  
  return(
    <Layout location={location}>
      <Metadata
        title="Garmin Pay &amp; Wallet Setup | Mobile Tap &amp; Pay | Gem by Latitude"
        description="Garmin Pay is now available to Gem Visa customers. See how to setup Garmin Pay on your Android devices and make your payments more simple and secure."
        canonical={`${SITE_URL}${location.pathname}`}
      />
      <Hero />
      <StickyNavigation />
      {state?.featureSliderData?.[0] && (
        <Box backgroundColor={COLOR.WHITE}>
          <FeaturesSlider
            heading={state.featureSliderData[0].heading}
            subheading={state.featureSliderData[0].description}
            data={state.featureSliderData[0].featureCards}
          />
        </Box>
      )}
      <Box.Section backgroundColor={COLOR.GREY6}>
        <Heading4 align={ALIGN.CENTER} color={COLOR.BLACK}>
          Set up and use Garmin Pay
        </Heading4>
        <Box
          css={`
            display: grid;
            grid-gap: ${MARGIN.M24} ${MARGIN.M40};
            h5 {
              margin-bottom: ${MARGIN.M16};
              color: ${COLOR.BLACK};
            }
            @media (max-width: ${BREAKPOINT.SM}) {
              h5 {
                font-size: ${HEADING.h6.FONT_SIZE.NORMAL};
                line-height: ${HEADING.h6.LINE_HEIGHT.NORMAL};
              }
            }
            @media (min-width: ${BREAKPOINT.LG}) {
              grid-template-columns: repeat(3, 1fr);
              grid-template-rows: 1;
            }
          `}
        >
          <Box>
            <Heading5>Set up on device</Heading5>
            <List>
              <ListItem>
                Once you&apos;ve installed the Garmin app onto your iOS or Android
                smartphone, you can add your Gem Visa card to Garmin Pay.
              </ListItem>
              <ListItem>
                Tap &apos;Create your wallet&apos; in the Garmin app and enter a
                4-digit passcode.
              </ListItem>
              <ListItem>
                Tap Visa to either fill in your card details or scan them using
                your smartphone&apos;s camera.
              </ListItem>
              <ListItem>
                We&apos;ll send you a one-time NetCode SMS. Use it to confirm you
                want to link your Gem Visa card to Garmin Pay. Your card will then
                be added to the virtual wallet on your Garmin Vivoactive 3 device.
              </ListItem>
            </List>
          </Box>
          <Box>
            <Heading5>How to use</Heading5>
            <List>
              <ListItem>
                To pay using your Garmin Vivoactive 3 device, press and hold the
                action button then tap the wallet icon, enter your 4-digit
                passcode and hold your smartwatch to the card reader to pay. A
                green tick confirms it’s been successful.
              </ListItem>
              <ListItem>
                To remove your card, simply tap the Vivoactive 3 device page in
                the Garmin Connect app &gt; Manage Wallet &gt; Swipe to find the
                card to remove &gt; Tap Delete Card.
              </ListItem>
            </List>
          </Box>
          <Box
            css={`
              text-align: center;
              @media (min-width: ${BREAKPOINT.LG}) {
                grid-area: 1/1;
              }
            `}
          >
            <img
              css={`
                max-width: 280px;
              `}
              src={imgHowToUse}
              alt="Watch - Garmin Pay"
            />
          </Box>
        </Box>
      </Box.Section>
      <HorizontalRule />
      <Box.Section backgroundColor={COLOR.GREY6}>
        <AccordionSidebar data={faqs.garminPay} />
      </Box.Section>
      <ImportantInformation
        data={{
          content: {
            importantInformationSectionOne: [
              'Garmin, the Garmin logo, and the Garmin delta are trademarks of Garmin Ltd. or its subsidiaries and are registered in one or more countries, including the U.S.'
            ],
            importantInformationSectionTwo: [
              'Garmin Pay is a trademark of Garmin Ltd. or its subsidiaries.'
            ]
          }
        }}
        sectionOneColummClasses="col-10 offset-1 col-lg-5 offset-lg-1"
        sectionTwoColummClasses="col-10 offset-1 col-lg-5 offset-lg-0"
      />
    </Layout>
  );
}

export default GarminPay;
